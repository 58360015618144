import el from './locales/el.json'
import en from './locales/en.json'

export default {
  fallbackLocale: 'el',
  messages: {
    en,
    el,
  },
  silentTranslationWarn: true,
  vueI18nLoader: true,
}
